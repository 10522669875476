<template>
  <div class="contenido">
    <ImageHeader />
    <div class="is-10 container-data container">
        <div class="columns">
            <div class="is-4 column has-text-left go-back" @click="$router.go(-1)">
                <span class="title white bold"><font-awesome-icon class="icono" :icon="['fal', 'chevron-left']"/> {{ $t("EVALUATOR.ALL_EVALUATORS.OTROS_EVALS")}}</span>
            </div>
        </div>
        <div class="container is-10 data">
            <div class="columns">
                <div class="column is-10 is-offset-1">
                    <table class="table">
                        <thead>
                        <tr>
                            <th v-for="(column, index) in columns" :key="index" scope="col" :class="{'has-text-centered': column.centerTitle}">
                                <b class="text-m">{{ $t(column.title) }}</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <template v-if="!Array.isArray(evaluadores)">
                                <tr>
                                    <td colspan="3" class="has-text-centered">{{ evaluadores }}</td> <!-- //TODO: Translate -->
                                </tr>
                            </template>
                            <template v-if="Array.isArray(evaluadores)">
                                <tr v-for="(item, index) in evaluadores" :key="index" @click="goProfile(item.usuario_id)">
                                    <td class="has-text-centered contracte-field" style="padding-top: 2.2rem;">
                                        {{item.usuario_id}}
                                    </td>
                                    <td class="email-text" style="padding-top: 2.2rem;"> {{ item.nombre }} {{ item.primer_apellido }} {{ item.segundo_apellido }}</td>
                                    <td class="has-text-centered bar-td">
                                        <ProgressBar :noLiterals="true" :withPadding="true" :value="item.num_evaluados" :total="item.num_relatos" />
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "OtrosEvaluadores",
  components: {
      ImageHeader,
      ProgressBar
  },
  data() {
    return {
        columns: [{
            title: 'EVALUATOR.ALL_EVALUATORS.NUMBER',
            centerTitle: true
        }, {
            title: 'EVALUATOR.ALL_EVALUATORS.NAME',
            centerTitle: false
        }, {
            title: 'EVALUATOR.ALL_EVALUATORS.PROGRESS',
            centerTitle: true
        }],
        evaluadores: [
        ]
    };
  },
  created() {
    this.$store.commit("setShowSpinner", true);
    console.log('Fase', this.$route.params.fase);
    this.$store
      .dispatch("getOtrosEvaluadores",  { idioma: this.$i18n.locale, provincia: this.$route.params.provincia, fase: 'evaluacion_1' })
    //   .dispatch("getOtrosEvaluadores",  { idioma: this.$i18n.locale, provincia: this.$route.params.provincia, fase: this.$route.params.fase })
      .then((evaluadores) => {
        this.evaluadores = evaluadores;
        this.$store.commit("setShowSpinner", false);
      })
      .catch((error) => {
        this.$store.commit("setShowSpinner", false);
      });
  },
  methods: {
    goProfile(usuarioId) {
        this.$router.push({name: 'perfil-evaluador', params: { usuarioId, provincia: this.$route.params.provincia } });
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.go-back{
    margin-bottom: 20px;
}

.go-back:hover{
    cursor: pointer;
}

.container-data{
    margin-top: -220px;
    padding-bottom: 120px;
}

.container.data{
    background-color: white;
}

.table {
    width: 100%;
    margin-bottom: 25px;
    border-collapse: separate;
    border-spacing: 0px 12px;
}


.table td, .table th {
    border: none;
    padding: 1em 1.25em;
}

.table tbody tr:hover{
    cursor: pointer;
}

.table tbody tr{
    background-color: $background-box;

    td:first-child {
        border-top-left-radius: 10px; 
        border-bottom-left-radius: 10px;
    }
    td:last-child {
        border-bottom-right-radius: 10px; 
        border-top-right-radius: 10px; 
    }
}

.profileImage {
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 20px;
}

</style>
